import React, { useRef, useState } from "react"
import { Layout } from "../components/layout"
import { Toast } from "../components/Toast"
import { graphql } from "gatsby";
import { useContactPage } from "../services/firestore";
import FindUs from "../components/findUs";
import emailjs from '@emailjs/browser';

const ContactForm = ({ preFillData }) => {
  const [toastList, setToastList] = useState([]);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault()
    emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
      .then(() => {
        setToastList([...toastList, {
          id: Math.floor(Math.random() * 101 + 1),
          title: "Réussi",
          class: "Success",
          description: "Votre message a bien eté envoyé",
          backgroundColor: "#5cb85c"
        }])
        e.target.reset()
      }, () => {
        setToastList([...toastList, {
          id: Math.floor(Math.random() * 101 + 1),
          title: "Erreur",
          class: "Danger",
          description: "Votre message à bien eté envoyé",
          backgroundColor: "#d9534f"
        }])
      });
  };

  return (
    <section className="highlight text-center px-4 ">
      <Toast toastList={toastList} position="top-right" />
      <form ref={form} onSubmit={sendEmail} id="contactForm">
        <div className="pt-16 pb-32">
          <p className="text-2xl"><strong>Une question ?</strong></p>
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 flex flex-col">
              <div className="flex lg:flex-row flex-wrap	">
                <div className="text-left mx-auto w-64 mt-16">
                  <p className="text-sm font-bold	">votre prénom & nom</p>
                  <input required className="w-64 bg-transparent text-center outline-none	p-2 border-2 pl-4 border-black rounded-3xl" type="text" name="name" />
                </div>
                <div className="text-left mx-auto w-64 mt-16">
                  <p className="text-sm font-bold	">votre email</p>
                  <input required className="w-64 bg-transparent text-center outline-none	p-2 border-2 pl-4 border-black rounded-3xl" type="email" name="email" />
                </div>
              </div>
              <div className="flex lg:flex-row flex-wrap">
                <div className="text-left mx-auto w-64 mt-16">
                  <p className="text-sm font-bold	">votre âge</p>
                  <input required className="w-64 bg-transparent text-center outline-none	p-2 border-2 pl-4 border-black rounded-3xl" type="number" name="age" />
                </div>
                <div className="text-left mx-auto w-64 mt-16">
                  <p className="text-sm font-bold	">sujet</p>
                  <select required className="w-64 bg-transparent text-center outline-none	p-2 border-2 pl-4 border-black rounded-3xl after:absolute after:top-4" name="subject" value={preFillData?.subject || ""}>
                    <option disabled={true}>-- Sélectionnez une option --</option>
                    <option>ainées</option>
                    <option>jeunesse</option>
                    <option>ados</option>
                    <option>mosaïque</option>
                    <option>baptèmes</option>
                    <option>besoin de prière</option>
                    <option>autre</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="lg:w-1/2">
              <div className="mx-auto text-center mt-16">
                <div class="w-8/12 mx-auto">
                  <p className="text-sm font-bold	text-left">votre message</p>
                  <textarea required name="message" defaultValue={preFillData?.defaultText || ""} rows={6} className="w-full bg-transparent outline-none	p-2 border-2 pl-4 border-black rounded-3xl resize-none" ></textarea>
                </div>
              </div>
            </div>
          </div>
          <input type="submit" className="mt-16 focus:outline-none focus:none text-white text-center	bg-primary py-2 px-8 border rounded-3xl mx-auto hover:bg-primary hover:text-white duration-500 " value="envoyer" />
        </div>
      </form>
    </section>
  );
};

const ContactPage = ({ location, data }) => {
  const page = useContactPage(data.allContactPages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Contact"}>
      {page && <FindUs></FindUs>}
      {page && <ContactForm preFillData={location.state}></ContactForm>}
    </Layout>
  );
};

export const query = graphql`
  query {
    allContactPages {
      nodes {
        title
        heroBannerType
        buttons {
          text
          url
          subject
          default
        }
        heroBannerImage {
          src
        }
      }
    }
  }
`;

export default ContactPage;
