import React, { useState, useEffect } from "react"
import "../styles/Toast.css"

export const Toast = (props) => {
    const { toastList, position, autoDelete = false, autoDeleteTime = 5000 } = props;
    const [list, setList] = useState(toastList);

    useEffect(() => {
        setList([...toastList]);
    }, [toastList]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (autoDelete && toastList.length && list.length) {
                deleteToast(toastList[0].id);
            }
        }, autoDeleteTime);

        return () => {
            clearInterval(interval);
        };
    }, [toastList, autoDelete, autoDeleteTime, list]);

    const deleteToast = (id) => {
        const listItemIndex = list.findIndex((e) => e.id === id);
        const toastListItem = toastList.findIndex((e) => e.id === id);
        list.splice(listItemIndex, 1);
        toastList.splice(toastListItem, 1);
        setList([...list]);
    };

    return (
        <>
            <div className="toastContainer">
                <div className={`notification-container ${position}`}>
                    {list.map((toast, i) => (
                        <div
                            key={i}
                            className={`notification toast ${position}`}
                            style={{ backgroundColor: toast.backgroundColor }}
                        >
                            <button onClick={() => deleteToast(toast.id)}>X</button>
                            <div>
                                <p className="notification-title">{toast.title}</p>
                                <p className="notification-message">{toast.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

