import React from "react";
import GoogleMapReact from "google-map-react";


function createMapOptions(maps) {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.LEFT_BOTTOM,
      },
      mapTypeControlOptions: {
        // position: maps.ControlPosition.TOP_RIGHT
      },
      mapTypeControl: true,
    };
  }
  
  const MapCreator = () => {
    const defaultProps = {
      center: {
        lat: 47.922852,
        lng: 1.940739,
      },
      zoom: 18,
    };
    const renderMarkers = (map, maps) => {
      const contentString =
        '<div id="content" class="text-black">' +
        '<div id="siteNotice">' +
        "</div>" +
        '<h1 id="firstHeading" class="firstHeading"><a href="https://www.google.com/maps/place/%C3%89glise+Parole+De+Vie+Orl%C3%A9ans/@47.9229253,1.938764,17z/data=!3m1!4b1!4m14!1m8!3m7!1s0x47e4fb5e522a1537:0xd9b9c36516b0a7be!2s%C3%89glise+Parole+De+Vie+Orl%C3%A9ans!8m2!3d47.9229217!4d1.9409527!9m1!1b1!3m4!1s0x47e4fb5e522a1537:0xd9b9c36516b0a7be!8m2!3d47.9229217!4d1.9409527?hl=en"><strong>Église Parole de Vie</strong></a></h1>' +
        '<div id="bodyContent">' +
        "<p> 5 rue Gustave Eiffel 45000 Orléans" +
        "</p>" +
        "</div>" +
        "</div>";
      const infowindow = new maps.InfoWindow({
        content: contentString,
      });
  
      let marker = new maps.Marker({
        position: { lat: 47.922852, lng: 1.940739 },
        map,
        title: "Église Parole de Vie",
        animation: maps.Animation.DROP,
      });
      marker.addListener("click", () => {
        infowindow.open({
          anchor: marker,
          map,
          shouldFocus: false,
        });
      });
      return marker;
    };
  
    return (
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyAf1dRqScFYwrNm0zwXDQJHhDFXn1SCpxQ" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        options={createMapOptions}
      ></GoogleMapReact>
    );
  };
  
  const FindUs = () => {
    return (
      <section className="find-us flex flex-col bg-primary text-white lg:flex-row lg:items-stretch lg:py-16">
        <div className="h-80 lg:h-auto lg:w-1/2 lg:px-8">
          <MapCreator />
        </div>
        <div className="p-4 lg:py-0 lg:flex-1 lg:px-32">
          <h4 className="text-2xl pb-8 font-semibold">Nous Rejoindre</h4>
          <div class="text-[1.1em]">
          <p>5 Rue Gustave Eiffel 45000, Orléans</p>
          <p>
            Située dans la ZAC des Châteliers en bordure de la tangentielle, face à la boulangerie
            "Ange".
          </p>
  
          <p className="mt-4">
            Bus : Ligne TAO n°3 arrêt <i>Belneuf</i> et n°7 arrêt <i>Clos Nécotin</i>.
          </p>
          <hr className="my-8"></hr> 
          <h4 className="mt-8 text-2xl mb-8 font-semibold">Nous Contacter</h4>
          <p>contact@paroledevie-orleans.fr</p>
          <p>02 38 54 84 84</p>
          </div>

        </div>
      </section>
    );
  };

  export default FindUs;
